import axios from 'axios';
import utility from '../../Utility/utility';

export const TIMEOUT = 15000;

const DEV_URL = 'https://eventmanagement.mwancloud.com/api/v1/';
const LIVE_URL = 'https://events.mwancloud.com/api/v1/';

let alias
let language
//console.log("Set language", language);  
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: TIMEOUT
});
instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel
instance.interceptors.response.use(response => response, (error) => {
  if (error.response.status == 404) {

  }
})
instance.interceptors.request.use(config => {
  let eventAlias = localStorage.getItem("alias")
  if (eventAlias != alias) {
    let languageItem = localStorage.getItem("language" + eventAlias) || 'en';
    language = languageItem
  }
  alias = eventAlias
  config.headers.locale = "en"
  if (!config.headers.Authorization) {
    let user = utility.getLoggedInUser(eventAlias)
    if (user) {
      config.headers.Authorization = `Bearer ${user.token}`
    }
  }
  return config
})
export default instance;
