const sponsors = (state = {}, action) => {
    switch (action.type) {
        case 'PARTNERS_DATA_LOADED' : 
            return{
            ...state,
            partnersData : action.payload
            }
        case 'PARTNERS_HOME_DATA_LOADED' : 
            return{
            ...state,
            partnersHomeData : action.payload
            }
        default:
            return state
        }
  }
  export default sponsors