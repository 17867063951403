var initialState = {
    dynamicPagesData : []
}
const pageData = (state = initialState, action) => {
    switch (action.type) {
        case 'PAGE_DATA_LOADED' : 
            return{
            ...state,
            dynamicPagesData : action.payload
            }
        default:
            return state
        }
  }
  export default pageData