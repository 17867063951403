const blogs = (state = {}, action) => {
    switch (action.type) {
        case 'BLOGS_DATA_LOADED' : 
            return{
            ...state,
            blogData : action.payload
            }
        default:
            return state
        }
  }
  export default blogs