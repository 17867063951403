import React, { useState, useEffect } from 'react'
import "./index.css"

const Header = (props) => {
    console.log("agendaTitles in header", props?.agendaTitles)
    const [isHovered, setIsHovered] = useState(false)
    const [isLeftHovered, setIsLeftHovered] = useState(false)
    const [isRightHovered, setIsRightHovered] = useState(false)

    // handling screen size change
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        // Event listener to update window width when the screen is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChange = (id) => {
        props.getUpdatedData(id)
    };


    return (
        <div>
            <section class="header header-simple bg_primary px-lg-3 pt-0 py-md-3 mt-lg-0 mt-3 " style={{ background: "#1B191E", borderBottom: "1px solid #F56A57 !important" }} >
                <div class="container-fluid d-block">
                    <div class="row align-items-center " >
                        <div class="col-md-12 col-lg-4 col-xl-4  ">
                            <div class="d-flex align-items-center justify-content-center ">
                                <a class="navbar-brand text-light" style={{ cursor: "pointer" }} onClick={() => window.open("https://machinescansee.com/", "_blank")} >
                                    <div class="logo-area v-zone-event-logo">
                                        {
                                            props?.menuIcon ?
                                                <img src={props?.menuIcon} alt="logo-img" class="w-100" />
                                                : null
                                        }
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-0 d-block d-md-none mb-2 mb-md-0" >
                            <div class="row" >
                                <div class="col-12 col-md-0 visit-text text-right position-rlative d-flex justify-content-lg-end justify-content-center mt-md-0 mt-3 mb-0" style={{ display: "flex", alignItems: "center" }} >
                                    <p onMouseEnter={() => windowWidth > 600 ? setIsHovered(true) : null} onMouseLeave={() => setIsHovered(false)} onClick={() => window.open("https://machinescansee.com/", "_blank")} style={{ color: isHovered ? "#F56A57" : "#FFFFFF", cursor: "pointer", border: "1px solid transparent", fontSize: "17px", fontFamily: "Open Sans", fontWeight: 500, display: "flex", justifyContent: "center", alignItems: "center" }} target="_blank" class="rounded-0 btn ">
                                        <i class='fas fa-globe' style={{ marginRight: "8px", }} ></i>
                                        Main Website
                                    </p>
                                </div>
                                <div class="col-12 col-md-0 mt-lg-0 mt-2 d-sm-flex justify-content-sm-center">
                                    <form action="">
                                        <fieldset>
                                            <div class="radio-block">
                                                <input type="radio" name="contrasts" onClick={() => handleChange(0)} id="contrasts-on" checked={props?.selectedIndex == 0} />
                                                <label for="contrasts-on" style={{ height: "40px", padding: "20px", marginTop: "5px" }} >{props?.agendaTitles[0]}</label>
                                                <input type="radio" name="contrasts" id="contrasts-off" checked={props?.selectedIndex == 1} onClick={() => handleChange(1)} />
                                                <label for="contrasts-off" style={{ height: "40px", padding: "20px", marginTop: "5px" }} class="off-label">{props?.agendaTitles[1]}</label>
                                                <span class="selected" aria-hidden="true"></span>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-md-block col-md-8 col-lg-5 col-xl-4 mt-lg-0 mt-4 ">
                            <form action="">
                                <fieldset>
                                    <div class="radio-block">
                                        <input type="radio" name="contrasts" onClick={() => handleChange(0)} id="contrasts-on" checked={props?.selectedIndex == 0} />
                                        <label for="contrasts-on" style={{ height: "40px", padding: "20px", marginTop: "5px" }} >{props?.agendaTitles[0]}</label>
                                        <input type="radio" name="contrasts" id="contrasts-off" checked={props?.selectedIndex == 1} onClick={() => handleChange(1)} />
                                        <label for="contrasts-off" style={{ height: "40px", padding: "20px", marginTop: "5px" }} class="off-label">{props?.agendaTitles[1]}</label>
                                        <span class="selected" aria-hidden="true"></span>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div class="col-md-4 col-lg-3 col-xl-4 text-right position-rlative d-none  d-md-flex justify-content-lg-end justify-content-center mt-md-0 mt-3 align-items-center " >
                            <p onMouseEnter={() => windowWidth > 600 ? setIsHovered(true) : null} onMouseLeave={() => setIsHovered(false)} onClick={() => window.open("https://machinescansee.com/", "_blank")} style={{ color: isHovered ? "#F56A57" : "#FFFFFF", cursor: "pointer", border: "1px solid transparent", fontFamily: "Open Sans", fontWeight: 500, display: "flex", justifyContent: "center", alignItems: "center" }} target="_blank" class="rounded-0 visit-text">
                                <i class='fas fa-globe' style={{ marginRight: "8px", }} ></i>
                                Main Website
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Header
