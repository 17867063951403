    const invitations = (state = {
        allInvitations : {
            sent : [],
            received : []
        }
    }, action) => {
    switch (action.type) {
        case 'INVITATIONS_LIST' :
            return{
                ...state,
                allInvitations : action.payload
            }
        default:
            return state
        }
  }
  export default invitations