import React from "react";
import Header from "../Header/NewIndex";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Session from "./session";
import Sticky from 'react-sticky-el';



export class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      isDataLoaded: false,
      event_alias: "machines-can-see-2024",
      sessionDetails: "",
      agenda: null,
      selectedIndex:
        this.props.headerMenuItems.findIndex(
          (x) => x.href.indexOf("/agenda") > -1
        ) || -1,
      selectedSubIndex: -1,
      loadingError: "",
      menu: "",
      showComingSoonMsg: false,
      showUserErrorPopup: false,
      errorMessageHeading: "",
      errorMessageJoin: "",
      showCloseButton: true,
      showSessionView: false,
      selectedSessionTitle: "",
      selectedSessionSpeakers: [],
      selectedSessionSponsors: [],
      webinarId: "",
      webinarPassword: "",
      streamType: 1,
      isVimeo: 0,
      streamLink: "",
      videoId: "",
      selectedSessionIndex: -1,
      selectedSubSessionIndex: -1,
      selectedAgendaId: 0,
      selectedSessionId: 0,
      selectedSubSessionId: 0,
      selectedSessionCerificateEnabled: 0,
      selectedSessionDuration: 0,
      sessionSavedQuestions: [],
      sessionSavedAnnouncements: [],
      userLoadedSessionTime: 0,
      overAllSessionsWatchedTime: 0,
      groupChat: [],
      showingLocalTime: false,
      virtualZoneItemIndex: this.props.virtualMenuItems.findIndex(
        (x) => x.path == "/agenda"
      ),
      requestingCertificate: false,
      cerificateRequestResponse: {},
      certificateRequestSent: false,
      premiumSessionText: "",
      defaultAgendaIndex: 0,
      loadingNextSession: false,
      selectedTimeZone: -1,
      selectedSessionPolls: [],
      pollQuestion: [],
      loaded: false,
      loadingAgendaFromUrl: false,
      selectedSessionPolls: [],
      realTimeData: false,
      unReadGroupChatCount: "",
      loadingSessionGroupChat: true,
      loadingSessionQuestions: true,
      loadingSessionAnnouncements: true,
      loadingSessionPolls: true,
      MessageCount: 0,
      saveUserSession: false,
      streamTypeSet: "",
      agendaIndex: null,
      sessionIndex: null,
      subSessionIndex: null,
      loadSessionData: false,
      certificateSave: false,
      multiLangualSessoins: [{}],
      selectedAgenda: this.props?.selectedAgendaDetails,
      selectedIndex: 0,
      is_vimeo: 0,
      link: ""
    };
    this.statusLoaded = false;
  }
  componentDidMount() {
    console.log(this.props);
    window.scrollTo(0, 0);
    //this.getInitialPageData()
    if (this.props?.selectedAgendaDetails) {
      this.setState({
        selectedSessionTitle: this.props?.selectedAgendaDetails.title, selectedSessionSpeakers: this.props?.selectedAgendaDetails?.speakers?.data, selectedSessionSponsors: this.props?.selectedAgendaDetails?.sponsers?.data, streamLink: this.props?.selectedAgendaDetails?.virtual_access_link,
        sessionDetails: this.props?.selectedAgendaDetails?.description, is_vimeo: this.props?.selectedAgendaDetails?.is_vimeo, streamLink: this.props?.selectedAgendaDetails?.virtual_access_link
      })
    }
  }
  componentWillReceiveProps(nextProps) {
  }
  getUpdatedData(index) {
    let selectedInd = index
    let targetSession = this.props?.agendaDetails[selectedInd]
    console.log("alllll", targetSession)
    this.setState({
      selectedSessionTitle: targetSession?.title, selectedSessionSpeakers: targetSession?.speakers?.data, selectedSessionSponsors: targetSession?.sponsers?.data, streamLink: targetSession?.virtual_access_link,
      sessionDetails: targetSession?.description, selectedIndex: selectedInd, is_vimeo: targetSession?.is_vimeo, streamLink: targetSession?.virtual_access_link
    })
  }
  render() {
    { console.log("selectedAgenda", this.props.agendaTitles, ":", this.props?.selectedAgendaDetails) }
    return (
      <div>
        <Header agendaTitles={this.props.agendaTitles} menuIcon={this.props?.menuIcon} getUpdatedData={(index) => this.getUpdatedData(index)} selectedIndex={this.state.selectedIndex} />
        <div className={`content-main`}>
          <Session
            ref={(ref) => (this.sessionRef = ref)}
            eventAlias={"machines-can-see-2024"}
            sessionDetails={this.state.sessionDetails}
            user={this.user}
            certificateSave={this.state.certificateSave}
            cancelSaveCertificate={this.cancelSaveCertificate}
            eventLogo={""}
            eventSponsors={this.props?.eventSponsors}
            getPollQuestion={this.getPollQuestion}
            clearCurrentAgendadata={() => { }}
            title={this.state.selectedSessionTitle}
            speakers={this.state.selectedSessionSpeakers}
            sponsors={this.state.selectedSessionSponsors}
            webinarId={this.state.webinarId}
            pollQuestion={this.state.pollQuestion}
            webinarPassword={this.state.webinarPassword}
            streamType={"live"}
            streamLink={this.state.streamLink}
            videoId={this.state.videoId}
            realTimePoll={this.state.realTimeData}
            agendaId={this.state.selectedAgendaId}
            sessionId={this.state.selectedSessionId}
            sessionDuration={this.state.selectedSessionDuration}
            subSessionId={this.state.selectedSubSessionId}
            sessionCertificateEnabled={false}
            savedQuestions={this.state.sessionSavedQuestions}
            savedAnnouncements={this.state.sessionSavedAnnouncements}
            lastQuestionId={this.lastLoadedQuestionId}
            savedSessionTime={this.state.userLoadedSessionTime}
            overAllSessionsWatchedTime={null}
            unReadGroupChatCount={this.state.unReadGroupChatCount}
            groupChat={this.state.groupChat}
            socket={this.props.socket}
            onClickBackToAgendaView={(link) => { }}
            NavigateToLogin={() => { }}
            certificateCompletionTime={this.certificateCompletionTime}
            eventCertificateCompletion={this.eventCertificateComplrtion}
            requestingCertificate={this.state.requestingCertificate}
            cerificateRequestResponse={
              this.state.cerificateRequestResponse
            }
            certificateRequestSent={this.state.certificateRequestSent}
            isVimeo={this.state.is_vimeo}
            sessionEndTime={this.state.sessionEndTime}
            nextSession={this.state.nextSession}
            onClickStartNewSection={this.startNewSession}
            loadingNextSession={this.state.loadingNextSession}
            eventTimeZone={""}
            selectedTimeZone={this.state.selectedTimeZone}
            defaultTimeZone={""}
            polls={this.state.selectedSessionPolls}
            onClickRequestCertificate={(data) => { }}
            loadingSessionGroupChat={this.state.loadingSessionGroupChat}
            loadingSessionQuestions={this.state.loadingSessionQuestions}
            loadingSessionAnnouncements={null}
            loadingSessionPolls={this.state.loadingSessionPolls}
            multiLangualSessoins={this.state.multiLangualSessoins}
            selectedVideoLocale={this.videoLocale}
            onLanguageChanged={(locale) => this.floatingPlayerLocale = locale}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Schedule);
