import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import utility from '../../Utility/utility';
import YouTube from 'react-youtube';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect'
// import Marquee from "react-fast-marquee";
// import Marquee from "react-easy-marquee";
import Marquee from "react-marquee-slider";
// import times from "lodash/times";
import './styles.css'

export class session extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedSpeaker: {},
            selectedTabIndex: isMobile ? 0 : 0,
            showSessionDisconnectPopup: false,
            chat: props.savedAnnouncements,
            questions: props.savedQuestions,
            unReadChatCount: "",
            unReadQuestionsCount: "",
            unReadGroupChatCount: props.unReadGroupChatCount,
            qMessage: "",
            sessionPassedTimePercent: 0,
            overAllSessionPassedTimePercent: 0,
            currentPassedTimePercent: 0,
            streamEnded: false,
            groupChat: props.groupChat,
            groupChatMessage: '',
            replyingToMessageId: '',
            replyChatMessage: {},
            showFullSpeakerDescription: false,
            showFullAttendeeBio: false,
            showNextSessionNotification: false,
            nextSessionTime: -1,
            sessionId: null,
            collapseID: "collapsibleNavbar0",
            selectedPollsFilter: 0,
            polls: props.polls,
            pollQuestion: [],
            polls_data: [],
            selectedAnswerId: [],
            selected_options: [],
            collapseid: [],
            collapseId: null,
            loader: false,
            loader_id: null,
            readPoll: false,
            numberOfNewPoll: 0,
            optionToSubmitedQuestion: [],
            filterTarget: '0',
            readPoll: true,
            type: '',
            loadingGroupChat: props.loadingSessionGroupChat,
            loadingQuestions: props.loadingSessionQuestions,
            loadingAnnouncements: props.loadingSessionAnnouncements,
            loadingPolls: props.loadingSessionPolls,
            realTime: true,
            attendeeName: '',
            attendeePhoto: '',
            attendeeCompanyName: '',
            attendeeJobTitle: "",
            attendeeprofileBio: '',
            activeTab: 0,
            newPoll: false,
            firstPoll: false,
            errorMessageHeading: "",
            errorMessageJoin: "",
            msgCount: 0,
            ProgresssMessage: '',
            videoId: props.videoId,
            multiLangualSessoins: props.multiLangualSessoins,
            selectedVideoLocale: props.selectedVideoLocale,
            availableVideoLocales: [],
            vimeoUrl: props.streamLink,
            screenWidth: window.innerWidth,
        }
        this.agendaIndex = -1
        this.sessionIndex = -1
        this.zoomChatButtonClicked = false
        this.zoomQAButtonClicked = false
        this.user = null
        this.unReadChatCount = 0
        this.unReadQuestionsCount = 0
        this.saveTimeAgenda = 0
        this.eventCertificationCompletionTime = props.certificateCompletionTime
        this.sessionCertificateCompletionTime = ((props.sessionDuration / 60) * 100) / 100
        this.currentCertificateCompletionTime = props.certificateCompletionTime
        this.sessionPassedTimeInSec = this.props.savedSessionTime
        this.overAllSessionsWatchedTimeInSec = props.overAllSessionsWatchedTime
        this.sessionWatched = props.overAllSessionsWatchedTime
        this.devTootInterval = null
        this.lastQuestionId = this.props.lastQuestionId
        this.youtubePlayer = null
        this.tempMessageData = []
        this.tempMessageDataForTimer = []
        this.secondsPassedAfterFirstMessage = 0
        this.timer = null
        this._isMounted = false
        this.certificateText = "Request For Certificate, If you have attended the event for certain time duration"
        this.videoTimerSecs = 0
        this.fullScreenMode = false
        this.inspectOpenCheckInterval = null
        this.saveSessionTime = true
        this.sessionEndTimer = null
        this.nextSessionRemainingTimeInterval = null
        this.sessionId = null
        this.videoPaused = false
        this.collapse = null
        this.translation = null
        this.handleResize = this.handleResize.bind(this)
    }
    componentWillReceiveProps(nextProps) {
    }
    componentDidUpdate(prevProps) {

    }
    componentDidMount() {
        this._isMounted = true
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        this._isMounted = false
        window.removeEventListener('resize', this.handleResize);
    }
    OnClickBio = (index) => {
        this.setState({ selectedSpeaker: this.props.speakers[index] }, () => {
            $("#spk-modal").modal('show');
        })
    }
    handleResize() {
        this.setState({ screenWidth: window.innerWidth });
    }
    showDialog = (id, type, replies = [], senderId = 0) => {

    }
    OnClickTab = (index) => {
        this.setState({ selectedTabIndex: index })
    }
    onReady = (event) => {
        this.youtubePlayer = event.target;
        if (isMobile) {
            setTimeout(() => {
                this.youtubePlayer.playVideo()
            }, 500);
        } else {
            this.youtubePlayer.playVideo()
        }

    };
    handleOnChangeVideo = (locale, updateUrl = true) => {
    }
    render() {
        console.log("All props:", this.props?.eventSponsors)
        let selectedSpeaker = this.state.selectedSpeaker
        const youtubeOptions = {
            height: '100%',
            width: '90%',
            playerVars: {
                start: 0,
                autoplay: 0,
                rel: 0,
                controls: 1,
                modestbranding: 1,
                showinfo: 0,
                autohide: 1,
                wmode: 'transparent'
            }
        };
        let selectedSpeakerDescription = ""
        if (selectedSpeaker != null) {
            selectedSpeakerDescription = utility.extractContent(selectedSpeaker.description)
        }
        return (<div>
            <section class="live_stream group-chat overflow-hidden position-relative" style={{ background: "#1B191E", }} >
                <div class="container-fluid " id="live_stream">
                    <div class="row group-chat-full">
                        <div class=" d-md-flex w-100" >
                            <div class="col-12 px-md-0" style={{ padding: 0 }}>
                                <div class="row live_screen mx-0">
                                    <div id="video-area" class="col-md-12 col-xl-7 px-0 pl-md-0">
                                        {this.props.isVimeo == 0 ?
                                            <div class="video-frame h-100" >
                                                <div style={{ backgroundColor: '#1B191E' }} >
                                                    <div class="embed-responsive embed-responsive-16by9 video-frame" style={{ backgroundColor: 'rgb(27, 25, 30)' }} >
                                                        <div id="video-frame" style={{ backgroundColor: '#1B191E', pointerEvents: 'all' }}>
                                                            <YouTube id="video-player" containerClassName="youtubeIframe" opts={youtubeOptions} videoId={utility.getYoutubeVideoId(this.props?.streamLink)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div class="video-frame" style={{ backgroundColor: '#1B191E', }}>
                                                <div class="embed-responsive embed-responsive-16by9 video-frame" style={{ backgroundColor: 'rgb(27, 25, 30)', paddingTop: "10px" }} >
                                                    <iframe id="video-frame" src={this.props.streamLink} autoplay={1} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.screenWidth < 992 ?
                                            <div class="col-12 d-block d-lg-none mt-md-0 mt-2 pl-md-0">
                                                {/* {this.props?.eventSponsors?.length > 0 ?
                                                    <>
                                                        <Marquee
                                                            //duration={80000}
                                                            //width="100%"
                                                            // axis="X"
                                                            // align="center"
                                                            //pauseOnHover={true}
                                                            //reverse={true}
                                                            speed={200}
                                                        //className="Marquee-content"
                                                        >
                                                            {this.props.eventSponsors.map((sponsor, index) => {
                                                                return (
                                                                    <div class="Marquee-tag">
                                                                        <img src={sponsor.image} alt="spon_logo" />
                                                                    </div>
                                                                )
                                                            })}
                                                        </Marquee>
                                                    </>
                                                    : null} */}

                                                {this.props?.eventSponsors?.length > 0 ?
                                                    <>
                                                        <Marquee velocity={5} className="Marquee-content">
                                                            {this.props.eventSponsors.map((sponsor, index) => {
                                                                return (
                                                                    <div class="Marquee-tag">
                                                                        <img src={sponsor.image} alt="spon_logo" />
                                                                    </div>
                                                                )
                                                            })}
                                                        </Marquee>
                                                    </>
                                                    : null}
                                            </div>
                                            : null
                                    }
                                    <div class="col-md-12 col-xl-5 px-0 d-block" style={{ background: "#1B191E" }}>
                                        <div class="tab-sides pr-md-0">
                                            <ul class="nav nav-fill nav-tabs session_tab mt-2 mt-md-0 desktop-tab " role="tablist">
                                                <li class="nav-item" style={{ background: this.state.selectedTabIndex == 0 ? "transparent" : "", }} >
                                                    <a onClick={() => this.OnClickTab(0)} class={this.state.selectedTabIndex == 0 ? "nav-link active" : "nav-link"} id="program_tab" data-toggle="tab" href="#program_tab">
                                                        <p class="icon-img certf" style={{ marginBottom: "10px" }} >
                                                            <i class="far fa-calendar-alt" style={{ color: this.state.selectedTabIndex == 0 ? "#F56A57" : "#D8D8D8" }} ></i>
                                                        </p>
                                                        <span class="link-title" style={{ color: this.state.selectedTabIndex == 0 ? "#F56A57" : "#D8D8D8" }} >PROGRAM</span>
                                                        <div class="title-show" style={{ color: this.state.selectedTabIndex == 0 ? "#F56A57" : "#D8D8D8", }} >
                                                            PROGRAM
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="nav-item" style={{ background: this.state.selectedTabIndex == 1 ? "transparent" : "", }} >
                                                    <a onClick={() => this.OnClickTab(1)} class={this.state.selectedTabIndex == 1 ? "nav-link active" : "nav-link"} id="speaker" data-toggle="tab" href="#spk_tab">
                                                        <p class="icon-img mic" style={{ marginBottom: "10px" }}>
                                                            <i class="fas fa-microphone" style={{ color: this.state.selectedTabIndex == 1 ? "#F56A57" : "#D8D8D8" }}></i>
                                                        </p>
                                                        <span class="link-title" style={{ color: this.state.selectedTabIndex == 1 ? "#F56A57" : "#D8D8D8" }}>SPEAKERS</span>
                                                        <div class="title-show" style={{ color: this.state.selectedTabIndex == 1 ? "#F56A57" : "#D8D8D8", }}>
                                                            SPEAKERS
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="tab-content d-none d-lg-block" id="session-tab-content">
                                                <div class={this.state.selectedTabIndex == 1 ? "tab-pane show active" : "tab-pane"} id="spk_tab" role="tabpanel" aria-labelledby="home-tab">
                                                    <div class="tab-pane-inner p-1">
                                                        <div className="speakers-inner mt-60">
                                                            {this.props.speakers.map((speaker, index) => {
                                                                return (
                                                                    <div class="d-flex spk_div">
                                                                        <div class="px-2 pt-2 float-left">
                                                                            {speaker.image != null ?
                                                                                <img src={speaker.image} class="rounded-circle mt-2 adj_user" alt="user_1-img" /> :
                                                                                <img src="/images/profile-placeholder.png" class="rounded-circle mt-2 adj_user" alt="user_1-img" />}
                                                                        </div>
                                                                        <div class="px-2 pt-2 float-right mt-2">
                                                                            <h6 style={{ color: "#FFFFFF" }} >{speaker.name + " " + (speaker.surname == null ? "" : speaker.surname)}</h6>
                                                                            <p class="mb-0 mt-1" style={{ color: "#FFFFFF" }}>{speaker.job_title}</p>
                                                                            <p style={{ color: "#FFFFFF" }}>{speaker.company}</p>
                                                                            <p style={{ color: "#FFFFFF" }}>
                                                                                {speaker.description != null ?
                                                                                    <a style={{ cursor: 'pointer', color: "#F56A57", fontStyle: "italic" }} onClick={() => this.OnClickBio(index)} id="bio" class="mr-2 spk_info-div">Full Bio</a> : null}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class={this.state.selectedTabIndex == 0 ? "tab-pane show active" : "tab-pane"} id="program_tab" role="tabpanel" aria-labelledby="programs-tab">
                                                    <div class="tab-pane-inner">
                                                        <div class="agenda-item"  >
                                                            {ReactHtmlParser(this.props?.sessionDetails)}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-content d-block d-lg-none" id="session-tab-content">
                                        <div class={this.state.selectedTabIndex == 1 ? "tab-pane show active" : "tab-pane"} id="spk_tab" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="tab-pane-inner p-1">
                                                <div className="speakers-inner mt-60">
                                                    {this.props.speakers.map((speaker, index) => {
                                                        return (
                                                            <div class="d-flex spk_div">
                                                                <div class="px-2 pt-2 float-left">
                                                                    {speaker.image != null ?
                                                                        <img src={speaker.image} class="rounded-circle mt-2 adj_user" alt="user_1-img" /> :
                                                                        <img src="/images/profile-placeholder.png" class="rounded-circle mt-2 adj_user" alt="user_1-img" />}
                                                                </div>
                                                                <div class="px-2 pt-2 float-right mt-2">
                                                                    <h6 style={{ color: "#FFFFFF" }} >{speaker.name + " " + (speaker.surname == null ? "" : speaker.surname)}</h6>
                                                                    <p class="mb-0 mt-1" style={{ color: "#FFFFFF" }}>{speaker.job_title}</p>
                                                                    <p style={{ color: "#FFFFFF" }}>{speaker.company}</p>
                                                                    <p style={{ color: "#FFFFFF" }}>
                                                                        {speaker.description != null ?
                                                                            <a style={{ cursor: 'pointer', color: "#F56A57", fontStyle: "italic" }} onClick={() => this.OnClickBio(index)} id="bio" class="mr-2 spk_info-div">Full Bio</a> : null}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div class={this.state.selectedTabIndex == 0 ? "tab-pane show active" : "tab-pane"} id="program_tab" role="tabpanel" aria-labelledby="programs-tab">
                                            <div class="tab-pane-inner">
                                                <div class="agenda-item"  >
                                                    {ReactHtmlParser(this.props?.sessionDetails)}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modall fade right" id="spk-modal-chat">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">

                                <div class="modal-body">
                                    <div class="row my-3">
                                        <div class="col-3 text-center">
                                            <div class="participant-image">
                                                {this.state.attendeePhoto != null && this.state.attendeePhoto != "" ?
                                                    <img src={this.state.attendeePhoto} alt="participant_image" /> :
                                                    <img src="/images/profile-placeholder.png" alt="participant_image" />}
                                            </div>
                                        </div>

                                        <div class="col-9 d-flex align-items-center">
                                            <div class="participant-details">
                                                <span class={`spk-full-name`}>
                                                    {this.state.attendeeName == null ? "" : this.state.attendeeName}
                                                </span>
                                                <div class="post">
                                                    {this.state.attendeeJobTitle} <br />
                                                    {this.state.attendeeCompanyName}
                                                </div>
                                            </div>
                                        </div>
                                        <a style={{ cursor: 'pointer' }} onClick={() => {
                                            $('#spk-modal-chat').modal('hide');
                                            $('.modal-backdrop').remove();
                                        }} class="close-icons" id="close-m">
                                            <img src="/images/ic_chat_close.svg" alt="close-icon-btn" />
                                        </a>
                                    </div>
                                    <div id="speakerDetail" class="spk-detail-text">
                                        {this.state.attendeeprofileBio != null && this.state.attendeeprofileBio != undefined ?
                                            <p class="details pr-3">
                                                <p style={{ textAlign: 'justify' }}>
                                                    {!this.state.showFullAttendeeBio && (this.state.attendeeprofileBio || '').length >= 700 ?
                                                        this.state.attendeeprofileBio.substring(0, 700) :
                                                        this.state.attendeeprofileBio}
                                                </p></p> : null}
                                        {this.state.attendeeprofileBio != null && (this.state.attendeeprofileBio || '').length >= 700 ?
                                            <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                this.setState({ showFullAttendeeBio: !this.state.showFullAttendeeBio }, () => {
                                                    if (!this.state.showFullAttendeeBio) {
                                                        var myDiv = document.getElementById('speakerDetail');
                                                        myDiv.scrollTop = 0
                                                    }
                                                })
                                            }} class="text-underline text-dark">{!this.state.showFullAttendeeBio ? "Show Full" : "Show Less"}</a> : null}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modall fade right" id="spk-modal">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="row my-3">
                                        <div class="col-3 text-center">
                                            <div class="participant-image">
                                                {selectedSpeaker.image != null ?
                                                    <img src={selectedSpeaker.image} alt="participant_image" /> :
                                                    <img src="/images/profile-placeholder.png" alt="participant_image" />}
                                            </div>
                                        </div>

                                        <div class="col-9 d-flex align-items-center">
                                            <div class="participant-details">
                                                <span class="spk-full-name">
                                                    {(selectedSpeaker.surname == null ? "" : selectedSpeaker.surname) + " " + selectedSpeaker.name}
                                                </span>
                                                <div class="post">
                                                    {selectedSpeaker.job_title} <br />
                                                    {selectedSpeaker.company}
                                                </div>
                                            </div>
                                        </div>
                                        <a style={{ cursor: 'pointer' }} onClick={() => {
                                            $('#spk-modal').modal('hide');
                                            $('.modal-backdrop').remove();
                                        }} class="close-icons" id="close-m">
                                            <img src="/images/ic_chat_close.svg" alt="close-icon-btn" />
                                        </a>
                                    </div>
                                    <div id="speakerDetail" class="spk-detail-text">
                                        {selectedSpeaker.description != null ?
                                            <p class="details pr-3">
                                                {!this.state.showFullSpeakerDescription && selectedSpeakerDescription.length >= 700 ?
                                                    ReactHtmlParser(selectedSpeaker.description.substring(0, 700)) :
                                                    ReactHtmlParser(selectedSpeaker.description)}
                                            </p> : null}
                                        {selectedSpeaker.description != null && selectedSpeakerDescription.length >= 700 ?
                                            <a style={{ cursor: 'pointer', color: "#F56A57", textDecoration: 'underline' }} onClick={() => {
                                                this.setState({ showFullSpeakerDescription: !this.state.showFullSpeakerDescription }, () => {
                                                    if (!this.state.showFullSpeakerDescription) {
                                                        var myDiv = document.getElementById('speakerDetail');
                                                        myDiv.scrollTop = 0
                                                    }
                                                })
                                            }} class="text-underline text-dark">{!this.state.showFullSpeakerDescription ? "Show Full" : "Show Less"}</a> : null}
                                    </div>

                                    {/* <p class = "mt-4 text-center">
                                <a href = {"mailto:"+this.state.selectedSpeaker.email} class="btn mx-1">
                                    Drop Email
                                </a>
                            </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="mobile-tab-bottom d-none" style={{ background: "#1B191E" }}>
                        <ul class="nav nav-tabs session_tab nav-fill" role="tablist">
                            <li class="nav-item mob-tab-item" >
                                <a onClick={() => {
                                    this.OnClickTab(0)
                                }} class={this.state.selectedTabIndex == 0 ? "nav-link active" : "nav-link"} id="program_tab1" data-toggle="tab" href="#program_tab1">
                                    <p class="icon-img certf" style={{ marginBottom: "5px" }} >
                                        <i class="far fa-calendar-alt" style={{ color: this.state.selectedTabIndex == 0 ? "#F56A57" : "#FFFFFF" }} ></i>
                                    </p>
                                    <p style={{ color: this.state.selectedTabIndex == 0 ? "#F56A57" : "#FFFFFF" }}  >
                                        PROGRAM
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item mob-tab-item">
                                <a onClick={() => this.OnClickTab(1)} class={this.state.selectedTabIndex == 1 ? "nav-link active" : "nav-link"} style={{ color: this.state.selectedTabIndex == 1 ? "#F56A57" : "#FFFFFF" }} id="speaker1" data-toggle="tab" href="#spk_tab1">
                                    <p class="icon-img mic">
                                        <i class="fas fa-microphone" style={{ color: this.state.selectedTabIndex == 1 ? "#F56A57" : "#FFFFFF" }}></i>
                                    </p>
                                    <p style={{ color: this.state.selectedTabIndex == 1 ? "#F56A57" : "#FFFFFF" }}  >
                                        Speakers
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
                <div class="section logo_section  sponsers-footer mb-md-0 mb-3" style={{ background: "#1B191E" }} >
                    <div class="container-fluid bg-white" style={{ background: "#1B191E", }}>
                        <div class="row live_screen flex-md-row-reverse flex justify-content-center align-content-center flex-column-reverse " style={{ background: "#1B191E" }} >
                            <div class="col-12 col-md-4 col-lg-3 col-xl-2 position-relative text-center pr-md-0 d-md-block mt-md-3 mt-2 flex-md-row flex-column" style={{ display: "flex", alignItems: "center" }} >
                                <p style={{ color: "#FFFFFF", fontSize: "13px", marginBottom: "8px" }} >POWERED BY</p>
                                <div class="logo-mevent" style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "10px" }} >
                                    <img src="/virtuo-logo.svg" class="com_logo" alt="mwan_event_logo" style={{ cursor: "pointer" }} onClick={() => window.open("https://virtuo.events/", "_blank")} />
                                    <div style={{ height: "20px", width: "2px", background: "#FFFFFF" }} ></div>
                                    <img src="/events-logo.svg" class="com_logo" alt="mwan_event_logo" style={{ cursor: "pointer" }} onClick={() => window.open("https://www.mwanevents.com/", "_blank")} />
                                </div>
                            </div>
                            {
                                this.state.screenWidth > 992 ?
                                    <div class="col-12 col-md-8 col-lg-9 col-xl-10 d-none d-lg-block mt-md-0 mt-2 pl-md-0">
                                        {this.props?.eventSponsors?.length > 0 ?
                                            <>
                                                <Marquee velocity={15} className="Marquee-content">
                                                    {this.props.eventSponsors.map((sponsor, index) => {
                                                        return (
                                                            <div class="Marquee-tag" style={{ cursor: sponsor?.website ? "pointer" : "default" }} onClick={() => sponsor?.website ? window.open(sponsor?.website, "_blank") : null} >
                                                                <img src={sponsor.image} alt="spon_logo" />
                                                            </div>
                                                        )
                                                    })}
                                                </Marquee>
                                            </>
                                            : null}
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}
export default compose()(session);