    const virtualLobby = (state = {
        hall : null,
        exhibitorsData : []
    }, action) => {
    switch (action.type) {
        case 'LOBBY_DATA' :
            return{
                ...state,
                lobby : action.payload
            }
        default:
            return state
        }
  }
  export default virtualLobby