const exhibitions = (state = {}, action) => {
    switch (action.type) {
        case 'EXHIBITION_DATA_LOADED_TEXTBLOCK' : 
            return{
            ...state,
            textBlockData : action.payload
            }
        case 'EXHIBITION_DATA_LOADED_ETEXTBLOCK' : 
            return{
            ...state,
            eTextBlockData : action.payload
            }
        case 'EXHIBITION_DATA_LOADED_FLOORPLAN' : 
            return{
            ...state,
            floorPlanData : action.payload
            }
        case 'EXHIBITION_DATA_LOADED_EXHIBITORS' : 
            return{
            ...state,
            exhibitorsData : action.payload
            }
        default:
            return state
        }
  }
  export default exhibitions